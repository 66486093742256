<template>
  <div class="follow-up-record">
    <el-timeline>
      <el-timeline-item
        v-for="(ele,index) in list"
        :key="index"
        :timestamp="ele.createTime + ele.programName"
        placement="top"
        color="#0476F5"
      >
        <el-card class="card-con">
          <div class="con-box">
            <div>
              <div class="con-item">方案名称：{{ele.oldProgramName}}</div>
              <div class="con-item">
                方案开始时间：{{ele.executionTime}}
                <span
                  v-if="ele.executionStatus == 1"
                  style="color:#999;"
                >未开始</span>
                <span v-if="ele.executionStatus == 2" style="color:#999;">进行中</span>
                <span v-if="ele.executionStatus == 3" style="color:#999;">已完成</span>
              </div>
              <div class="con-item">
                方案详情：
                <el-button type="text" style="padding-left:20px;" @click="getPlanInfo(ele)">查看详情</el-button>
                <el-button type="text" @click="toEditPlan(ele)">方案编辑</el-button>
              </div>
            </div>
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <!-- <el-dialog title="方案预览" :visible.sync="visible" width="700px" :close-on-click-modal="false">
      <div class="plan-preview-box">
        <div class="search-box">
          <el-select placeholder="治疗阶段" v-model="selectValue" clearable style="width:200px;">
            <el-option
              v-for="(item,index) in dictList"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-select placeholder="活动类型" v-model="selectValue2" clearable style="width:200px;">
            <el-option
              v-for="(item,index) in dictList2"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-select placeholder="活动类型" v-model="selectValue3" clearable style="width:200px;">
            <el-option
              v-for="(item,index) in dictList3"
              :key="index"
              :value="item.value"
              :label="item.name"
            ></el-option>
          </el-select>
        </div>
      </div>
    </el-dialog>-->
    <preview v-if="showPreviewDialog" ref="preview" :executionDay='executionDay' :list="allList"></preview>
  </div>
</template>

<script>
import Preview from "@/views/zykgl/components/preview.vue";
export default {
  props: {
    patiencePrograms: {
      type: Array
    }
  },
  components: {
    Preview
  },
  data() {
    return {
      list: [],
      visible: false,
      selectValue: "", //治疗阶段
      // dictList: [], //治疗阶段列表
      // selectValue2: "", //活动类型
      // dictList2: [], //活动类型列表
      // selectValue3: "", //日程类型
      // dictList3: [], //日程类型列表
      showPreviewDialog: false, //预览方案
      allList: [],
      executionDay:''
    };
  },
  methods: {
    init() {
      let _this = this;
      

      _this.list = _this.patiencePrograms;
    },
    // 进入个人方案编辑页面
    toEditPlan(ele) {
      let _this = this;
      _this.$router.push({
        name: "mbyhglEditPlan",
        query: { patienceId: ele.patienceId,programInfoId: ele.id, programId: ele.programId, edit: true }
      });
    },
    async getPlanInfo(ele) {
      let _this = this;
      console.log(ele);
      const {data:res} = await this.$http({
        url:this.$http.adornUrl('/programInfo/getProgramInfo'),
        method:"post",
        params:{id:ele.programId}
      })
      this.executionDay = res.data.executionDay
      _this.getProgramInfo(ele.programId);
    },
    // 获取方案活动详情
    getProgramInfo(programId) {
      let _this = this;
      let params = {
        programInfoId: programId,
        pageSize:999
      };
      _this
        .$http({
          url: _this.$http.adornUrl("/programActivity/list"),
          method: "get",
          params: params
        })
        .then(({ data }) => {
          if (data.status) {
            _this.allList = data.data;
            _this.showPreviewDialog = true;
            _this.$nextTick(() => {
              _this.$refs.preview.init();
            });
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.follow-up-record {
  padding: 40px;
  .title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .card-con {
    .con-box {
      display: flex;
      img {
        width: 50px;
        height: 50px;
      }
      .con-item {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        span {
          padding-left: 20px;
        }
      }
    }
  }
}
</style>

